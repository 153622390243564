







































import { defineComponent, ref } from '@vue/composition-api'
import LineSplitter from '@/components/animation/LineSplitter.vue'
import WebP from '@/components/WebP.vue'
import { motionOK } from '@/inc/utils/motionOK'
import gsap from 'gsap'

export default defineComponent({
  name: 'jobs-listing-title',
  components: {
    LineSplitter,
    WebP,
  },
  props: {
    content: {
      required: true,
      type: Object,
    },
  },
  setup() {
    const titleRef = ref()
    const subtitleRef = ref()
    const descriptionRef = ref()
    const visualRef = ref()

    const animateIn = () => {
      if (motionOK()) {
        const TL = gsap.timeline({
          defaults: {
            duration: 1,
            ease: 'power4.out',
          },
        })

        if (titleRef.value) {
          TL.add(titleRef.value.getTween(), 'start')
        }

        if (subtitleRef.value) {
          TL.add(subtitleRef.value.getTween(), 'start+=0.1')
        }

        if (visualRef.value) {
          TL.add(visualRef.value.getTween().resume(), 'start+=0.25')
        }

        if (descriptionRef.value) {
          TL.add(descriptionRef.value.getTween(), 'start+=0.5')
        }
      }
    }

    return {
      animateIn,
      titleRef,
      subtitleRef,
      descriptionRef,
      visualRef,
    }
  },
})
