



















































































































import {
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
} from '@vue/composition-api'
import { useGetters, useMutations } from '@u3u/vue-hooks'
import WebP from '@/components/WebP.vue'
import gsap from 'gsap'

export default defineComponent({
  name: 'values-panel',
  components: {
    WebP,
  },
  props: {
    content: {
      required: true,
      type: Array as () => Array<{
        title: string
        picture: string
        htmltext: string
      }>,
    },
  },
  setup() {
    const { modalContent } = useGetters('ui', ['modalContent'])
    const {
      PREVIOUS_PANEL: previousPanel,
      NEXT_PANEL: nextPanel,
      UNSET_PANEL: unsetPanel,
    } = useMutations('ui', ['PREVIOUS_PANEL', 'NEXT_PANEL', 'UNSET_PANEL'])

    const panel = ref<HTMLElement | null>(null)
    const inner = ref<HTMLElement | null>(null)
    const activeSlideIndex = ref(modalContent)

    const prev = () => {
      previousPanel()
    }

    const next = () => {
      nextPanel()
    }

    const close = () => {
      const tl = gsap.timeline({
        onComplete: () => {
          unsetPanel()
        },
      })

      tl.to(inner.value, {
        opacity: 0,
        y: 100,
        duration: 0.5,
        ease: 'power4.out',
      })

      tl.to(panel.value, {
        opacity: 0,
        duration: 0.5,
        ease: 'power4.out',
      })
    }

    const titleAnimateIn = (el: HTMLElement) => {
      animateInReverse(el.querySelectorAll('.js-animate-reverse'))
    }

    const contentAnimateIn = (el: HTMLElement) => {
      animateIn(el.querySelectorAll('.js-animate'))
    }

    const animateIn = target => {
      gsap.fromTo(
        target,
        {
          y: 40,
        },
        {
          y: 0,
          duration: 1,
          stagger: 0.004,
          ease: 'power3.out',
        }
      )
    }

    const animateInReverse = target => {
      gsap.fromTo(
        target,
        {
          y: -40,
        },
        {
          y: 0,
          duration: 1,
          stagger: 0.004,
          ease: 'power3.out',
        }
      )
    }

    const visualAnimateIn = target => {
      gsap.fromTo(
        target,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 1,
          stagger: 0.004,
          ease: 'power3.out',
        }
      )
    }

    const animateOut = (el: HTMLElement, done: () => void) => {
      gsap.to(el, {
        opacity: 0,
        duration: 0.2,
        onComplete: () => {
          done()
        },
      })
    }

    onMounted(() => {
      window.addEventListener('keyup', event => {
        if (event.key === 'Escape') {
          close()
        }
      })

      const tl = gsap.timeline()

      tl.from(panel.value, {
        opacity: 0,
        duration: 0.25,
        ease: 'power4.in',
      })

      tl.from(
        inner.value,
        {
          opacity: 0,
          y: 100,
          ease: 'power4.in',
        },
        '-=0.2'
      )
    })

    onBeforeUnmount(() => {
      window.removeEventListener('keyup', close)
    })

    return {
      activeSlideIndex,
      close,
      prev,
      next,
      panel,
      inner,
      animateIn,
      animateInReverse,
      animateOut,
      titleAnimateIn,
      contentAnimateIn,
      visualAnimateIn,
    }
  },
})
