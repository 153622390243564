































































































































































































































































/* eslint-disable no-undef */
/* eslint-disable max-len */
/* eslint-disable quote-props */
import {
  defineComponent,
  ref,
  onMounted,
  onUnmounted,
} from '@vue/composition-api'
import { debounce } from 'throttle-debounce'
import gsap from 'gsap'
import IconLink from '@/components/IconLink.vue'
import Draggable from '@/inc/vendor/gsap/Draggable'
import SplitText from '@/inc/vendor/gsap/SplitText'

const AUTOPLAY_SPEED = 10

export default defineComponent({
  name: 'expertises-slideshow',
  components: { IconLink },
  props: {
    content: {
      required: true,
      type: Object,
    },
  },
  setup(props) {
    const activeSlideIndex = ref(0)
    const draggable = ref()
    const draggableNavRef = ref()

    const computeDragBounds = () => {
      const containerWidth = draggableNavRef.value.getBoundingClientRect().width

      const listItems = draggableNavRef.value.querySelectorAll('li')
      let contentWidth = 0
      listItems.forEach(item => {
        contentWidth += item.getBoundingClientRect().width
      })

      console.log({
        left: -contentWidth + containerWidth,
        width: contentWidth,
      })

      return {
        left: -contentWidth + containerWidth,
        width: contentWidth,
      }
    }

    // If content isn't overflowing container, there is no need to enable the draggable
    const autoToggleDraggable = () => {
      if (draggable.value[0].minX > -1) {
        draggable.value[0].disable()
      } else {
        draggable.value[0].enable()
      }
    }

    const onResize = debounce(250, () => {
      // Apply new bounds
      draggable.value[0].applyBounds(computeDragBounds())

      // Disabled or enable drag based on computed bounds
      autoToggleDraggable()
    })

    onMounted(() => {
      window.addEventListener('resize', onResize)

      draggable.value = Draggable.create(draggableNavRef.value, {
        type: 'x',
        inertia: true,
        bounds: computeDragBounds(),
      })

      autoToggleDraggable()

      autoPlay(activeSlideIndex.value)
    })

    onUnmounted(() => {
      autoPlayTween.value.kill()
      draggable.value[0].kill()
      onResize.cancel()
      window.removeEventListener('resize', onResize)
    })

    const navigationItemsRef = ref([] as Array<HTMLElement>)
    const autoPlayTween = ref()
    let autoPlayEnabled = true

    const goTo = index => {
      activeSlideIndex.value = index

      updateSiblingsProgress(index)

      scrollToActiveNavItem(index)

      if (autoPlayEnabled) {
        autoPlay(activeSlideIndex.value)
      }
    }

    const prev = () => {
      clickNavItem(activeSlideIndex.value - 1)
    }

    const next = () => {
      clickNavItem(activeSlideIndex.value + 1)
    }

    const getNextIndex = () =>
      activeSlideIndex.value < props.content.items.length - 1
        ? activeSlideIndex.value + 1
        : 0

    const autoPlay = index => {
      const target = navigationItemsRef.value[index]

      gsap.set(target, {
        '--progress': 0,
      })

      autoPlayTween.value = gsap.to(target, {
        '--progress': 1,
        duration: AUTOPLAY_SPEED,
        ease: 'none',
        onComplete: () => {
          goTo(getNextIndex())
        },
      })
    }

    const updateSiblingsProgress = currentIndex => {
      navigationItemsRef.value.forEach((item, i) => {
        if (i < currentIndex) {
          gsap.set(item, {
            '--progress': 1,
          })
        } else if (i > currentIndex) {
          gsap.set(item, {
            '--progress': 0,
          })
        } else if (i === currentIndex) {
          gsap.set(item, {
            '--progress': autoPlayEnabled ? 0 : 1,
          })
        }
      })
    }

    const wrapperRef = ref()
    const scrollToActiveNavItem = index => {
      let x = 0
      for (let i = 0; i < index; i++) {
        x -= navigationItemsRef.value[i].getBoundingClientRect().width
      }

      gsap.to(draggableNavRef.value, {
        x: Math.max(draggable.value[0].minX, x),
        ease: 'power3.out',
        duration: 1,
      })
    }

    const titleAnimateIn = (el: HTMLElement, done: () => void) => {
      animateIn(el.querySelectorAll('.js-animate')).then(done)
    }

    const contentAnimateIn = (el: HTMLElement, done: () => void) => {
      const splitContent = el.querySelector('.js-split')

      // TextSplitter does not works by line with HTML content.
      // (even https://greensock.com/forums/topic/18243-split-text-confused/?tab=comments#comment-84134)
      // Running SplitText by words twice is the most straight forward to create the mask effect 🤷
      // Could obviously be improved. At this time it is smooth enough.

      // Create an overflow: hidden mask around every word
      // eslint-disable-next-line no-new
      new SplitText(splitContent, {
        type: 'words',
        wordsClass: 'v-c',
        tag: 'span',
      })

      // Within every mask, wrap word in another DIV that will be animated
      // eslint-disable-next-line no-new
      new SplitText(splitContent, {
        type: 'words',
        wordsClass: 'js-animate',
        tag: 'span',
      })

      animateIn(el.querySelectorAll('.js-animate')).then(done)
    }

    const clickNavItem = index => {
      autoPlayEnabled = false
      autoPlayTween.value.kill()
      goTo(index)
    }

    // NOTES:
    // - Purposefully use px units instead of percentage based units because it results if far better performances.
    // - Even if the value doesn't change, adding a unperceptible rotateX make the whole animation somehow smoother.
    const animateIn = target =>
      gsap.fromTo(
        target,
        {
          y: 40,
          rotateX: 0.001,
        },
        {
          y: 0,
          rotateX: 0.001,
          duration: 1,
          stagger: 0.004,
          ease: 'power3.out',
        }
      )

    const animateOut = (el: HTMLElement, done: () => void) => {
      gsap.to(el, {
        opacity: 0,
        duration: 0.1,
        onComplete: () => {
          done()
        },
      })
    }

    return {
      activeSlideIndex,
      draggableNavRef,
      onResize,
      navigationItemsRef,
      prev,
      next,
      clickNavItem,
      contentAnimateIn,
      titleAnimateIn,
      animateOut,
      wrapperRef,
    }
  },
})
