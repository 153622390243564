




















import { defineComponent, ref } from '@vue/composition-api'
import { useGetters, useMutations } from '@u3u/vue-hooks'
import { motionOK } from '@/inc/utils/motionOK'
import gsap from 'gsap'
import ValuesCard from '@/components/jobs/ValuesCard.vue'
import ValuesPanel from '@/components/jobs/ValuesPanel.vue'

export default defineComponent({
  name: 'values-grid',
  components: {
    ValuesCard,
    ValuesPanel,
  },
  props: {
    content: {
      required: true,
      type: Object,
    },
  },
  setup() {
    const { modalVisible } = useGetters('ui', ['modalVisible'])
    const { SET_PANEL: setPanel } = useMutations('ui', ['SET_PANEL'])

    const titleRef = ref()
    const subtitleRef = ref()
    const descriptionRef = ref()
    const visualRef = ref()

    const animateIn = () => {
      if (motionOK()) {
        const TL = gsap.timeline({
          defaults: {
            duration: 1,
            ease: 'power4.out',
          },
        })

        if (titleRef.value) {
          TL.add(titleRef.value.getTween(), 'start')
        }

        if (subtitleRef.value) {
          TL.add(subtitleRef.value.getTween(), 'start+=0.1')
        }

        if (visualRef.value) {
          TL.add(visualRef.value.getTween().resume(), 'start+=0.25')
        }

        if (descriptionRef.value) {
          TL.add(descriptionRef.value.getTween(), 'start+=0.5')
        }
      }
    }

    const clickNavItem = index => {
      console.log('GOTO', index)
      // GoTo(index)
      setPanel(index)
    }

    return {
      animateIn,
      titleRef,
      subtitleRef,
      descriptionRef,
      visualRef,
      clickNavItem,
      modalVisible,
    }
  },
})
