




























import { defineComponent } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'
import JobsListingTitle from '@/components/jobs/ListingTitle.vue'
import KeyPoints from '@/components/KeyPoints.vue'
import ValuesGrid from '@/components/jobs/ValuesGrid.vue'
import DuoCtas from '@/components/jobs/DuoCtas.vue'
import JobsSlider from '@/components/jobs/Slider.vue'
import Quote from '@/components/jobs/Quote.vue'
import { fakeData } from '@/data/fakeData.js'

export default defineComponent({
  name: 'jobs-listing',
  components: {
    DuoCtas,
    JobsListingTitle,
    JobsSlider,
    KeyPoints,
    Quote,
    ValuesGrid,
  },
  setup() {
    const { content } = useGetters(['content'])

    return {
      content,
      fakeData,
    }
  },
})
