<template>
  <div>
    <div class="float-label">
      <input
        :id="id"
        type="file"
        size="10000"
        accept=".pdf, .jpg, .xls, .doc"
        class="input float-label__input"
        :class="{
          'has-value': value,
          pristine: pristine,
        }"
        :required="data.required"
        @input="onInput"
        @blur="untouched = false"
        @invalid="untouched = false"
      />

      <label :for="id" class="label">
        <div class="label__float-container">
          <span
            class="label__float"
            :class="{
              floating: files.length > 0,
            }"
          >
            {{ data.label }}
          </span>

          <ul class="file-list reset">
            <li v-for="(file, i) in files" :key="i" class="file-list__item">
              {{ file.name }}
            </li>
          </ul>
        </div>
        <Curtain
          type="span"
          :label="data.button.label"
          class="upload-button button--has-curtain--dark"
        />
      </label>
    </div>
    <div class="instructions-list">
      <p
        class="instructions-list__item"
        v-for="(instruction, i) in data.instructions"
        :key="i"
      >
        {{ instruction }}
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.input {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-width: 0;
  clip-path: inset(100% 100% 0 0);
}

.label {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 4rem;
  margin: 0 0 2rem;
  cursor: pointer;
  line-height: 2; // Override default line-height for this specific input

  @include mq($from: xs) {
    flex-direction: row;
  }
}

.label__float-container {
  position: relative;
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin-bottom: 2rem;
  border-bottom: 2px currentColor solid;

  @include mq($from: xs) {
    @include liquid(margin-right, 20px, 50px);

    margin-bottom: 0;
  }
}

.label__float {
  transform-origin: 0 50%;
  display: block;
  white-space: nowrap;
  opacity: 1;
  transition: all $transition-duration $easing;
  transform: translateY(0%) scale(1);

  &.floating {
    transform: translateY(-60%) scale(0.75);
    opacity: 0.4;
  }
}

.file-list {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  margin: 0;
  font-size: 2rem;
  line-height: 2;
  pointer-events: none;
}

.file-list__item {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.instructions-list {
  display: flex;
  flex-wrap: wrap;
  font-size: 1.5rem;
  opacity: 0.4;
}

.instructions-list__item {
  margin-right: 2rem;
  margin-bottom: 1rem;
}

::v-deep {
  .upload-button {
    .curtain__inner {
      @include liquid(padding-right, 20px, 40px);
      @include liquid(padding-left, 20px, 40px);

      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }
  }
}
</style>

<script>
import { defineComponent, ref, computed } from '@vue/composition-api'
import axios from 'axios'

export default defineComponent({
  name: 'contact-form',
  props: {
    data: {
      required: true,
      type: Object,
    },
    id: {
      required: false,
    },
    required: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    const files = ref([])
    const untouched = ref(true)

    const pristine = computed(() => untouched.value && !props.value)

    const reset = () => {
      ctx.emit('input', null)
      untouched.value = true
    }

    const onInput = e => {
      files.value = []

      for (const file of e.target.files) {
        files.value.push({
          name: file.name,
          type: file.type,
          file,
        })
      }
    }

    const upload = () =>
      new Promise((resolve, reject) => {
        if (files.value && files.value.length > 0) {
          const { url } = props.data.button

          axios
            .put(url, files.value[0].file, {
              headers: {
                'X-Amz-Acl': 'public-read',
                'Content-Type': files.value[0].type,
              },
            })
            .then(response => {
              response.fileUploaded = true
              resolve(response)
            })
            .catch(reject)
        } else {
          resolve({
            status: 200,
            statusText: 'OK',
          })
        }
      })

    return {
      files,
      onInput,
      upload,
      pristine,
      untouched,
      reset,
    }
  },
})
</script>
