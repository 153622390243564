























import { defineComponent, ref } from '@vue/composition-api'
import Arrow from '@/components/Arrow.vue'
import WebP from '@/components/WebP.vue'
import { motionOK } from '@/inc/utils/motionOK'
import gsap from 'gsap'

export default defineComponent({
  name: 'values-card',
  components: {
    Arrow,
    WebP,
  },
  props: {
    content: {
      required: true,
      type: Object,
    },
  },
  setup() {
    const arrowRef = ref()

    const animateIn = () => {
      if (motionOK()) {
        const TL = gsap.timeline({
          defaults: {
            duration: 1,
            ease: 'power4.out',
          },
        })

        if (arrowRef.value) {
          TL.add(arrowRef.value.getTween(), 'start+=0.75')
        }
      }
    }

    return {
      animateIn,
      arrowRef,
    }
  },
})
