






















































































































































































/* eslint-disable quote-props */
import { defineComponent, ref, onMounted } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'
import ContactForm from '@/components/ContactForm.vue'
import gsap from 'gsap'
import { motionOK } from '@/inc/utils/motionOK'
import LineSplitter from '@/components/animation/LineSplitter.vue'
import HorizontalLine from '@/components/animation/HorizontalLine.vue'
import Strech from '@/components/animation/Strech.vue'

export default defineComponent({
  components: {
    ContactForm,
    LineSplitter,
    HorizontalLine,
    Strech,
  },
  setup(props, ctx) {
    const { content } = useGetters(['content'])
    const currentJob = ref()
    const kindJob = ref()

    onMounted(() => {
      currentJob.value = ctx.root.$route.query.jobTitle
        ? ctx.root.$route.query.jobTitle
        : ctx.root.$route.query.job

      kindJob.value = ctx.root.$route.query.job

      if (motionOK()) {
        if (servicesButtonRef.value) {
          servicesButtonRef.value.forEach(el => {
            gsap.set(el.$el, {
              opacity: 0,
              yPercent: 100,
            })
          })
        }
      }
    })

    const introTitleRef = ref()
    const introSubtitleRef = ref()
    const introAnimateIn = () => {
      if (motionOK()) {
        const TL = gsap.timeline({
          defaults: {
            duration: 1,
            ease: 'power4.out',
          },
        })

        if (introTitleRef.value) {
          TL.add(introTitleRef.value.getTween(), 'start')
        }
        if (introSubtitleRef.value) {
          TL.add(introSubtitleRef.value.getTween(), 'start+=0.25')
        }
      }
    }

    const titleRef = ref()
    const baselineRef = ref()
    const servicesHrRef = ref()
    const servicesTitleRef = ref()
    const servicesContentRef = ref()
    const servicesButtonRef = ref()
    const formSectionAnimateIn = () => {
      if (motionOK()) {
        const stagger = 0.075
        let index = 0

        const TL = gsap.timeline({
          defaults: {
            duration: 1,
            ease: 'power4.out',
          },
        })

        if (titleRef.value) {
          TL.add(titleRef.value.getTween(), 'start')
        }

        if (baselineRef.value) {
          index += 1
          TL.add(baselineRef.value.getTween(), `start+=${index * stagger}`)
        }

        if (servicesHrRef.value) {
          index += 1
          TL.add(servicesHrRef.value.getTween(), `start+=${index * stagger}`)
        }

        if (servicesTitleRef.value) {
          index += 1
          TL.add(servicesTitleRef.value.getTween(), `start+=${index * stagger}`)
        }

        if (servicesContentRef.value) {
          index += 1
          TL.add(
            servicesContentRef.value.getTween(),
            `start+=${index * stagger}`
          )
        }
      }
    }

    const addressTitleRef = ref()
    const adressesHrRef = ref()
    const adressesContentRef = ref()
    const addressesAnimateIn = () => {
      if (motionOK()) {
        const stagger = 0.075
        let index = 0

        const TL = gsap.timeline({
          defaults: {
            duration: 1,
            ease: 'power4.out',
          },
        })

        if (addressTitleRef.value) {
          TL.add(addressTitleRef.value.getTween(), 'start')
        }

        content.value.content.address.items.forEach((_address, i) => {
          if (adressesHrRef.value[i]) {
            index += 1
            TL.add(
              adressesHrRef.value[i].getTween(),
              `start+=${index * stagger}`
            )
          }
          if (adressesContentRef.value[i]) {
            index += 1
            TL.add(
              adressesContentRef.value[i].getTween(),
              `start+=${index * stagger}`
            )
          }
        })
      }
    }

    return {
      content,
      introTitleRef,
      introSubtitleRef,
      introAnimateIn,
      titleRef,
      baselineRef,
      servicesHrRef,
      servicesTitleRef,
      servicesContentRef,
      servicesButtonRef,
      formSectionAnimateIn,
      addressTitleRef,
      adressesHrRef,
      adressesContentRef,
      addressesAnimateIn,
      currentJob,
      kindJob,
    }
  },
})
